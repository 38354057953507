import { combineReducers } from "redux";
import auth from "./authReducers";
import { accounts, users } from "./accountReducers";
import { interviews } from "./interviewReducers";

export default combineReducers({
  auth,
  accounts,
  users,
  interviews
});
