import React from "react";
import { Layout } from "antd";
import SiderMenu from "./SiderMenu";
const { Sider } = Layout;

const SiderMenuWrapper = props => {
  return (
    <Sider
      style={{
        overflow: "auto",
        minHeight: "100vh"
      }}
      {...props}
    >
      <SiderMenu {...props} />
    </Sider>
  );
};

export default SiderMenuWrapper;
