import React, { Component } from "react";
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import isEmpty from "lodash.isempty";
import { Redirect } from "react-router";

import { fetchUser } from "./actions";

import SiderMenu from "./components/SiderMenu/index";
import { Layout, Spin, Icon } from "antd";
import GlobalFooter from "ant-design-pro/lib/GlobalFooter";

import GlobalHeader from "./components/GlobalHeader";

import Accounts from "./pages/Accounts";
import Login from "./pages/Login";

const { Content, Header, Footer } = Layout;

class App extends Component {
  state = {
    collapsed: false
  };

  componentDidMount() {
    const { auth, location } = this.props;
    if (
      location.pathname &&
      location.pathname !== "/login" &&
      location.pathname !== "/register"
    ) {
      this.props.fetchUser();
    }
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  renderLoading() {
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            height: "100vh"
          }}
        >
          <Spin size="large" />
        </div>
      </React.Fragment>
    );
  }

  renderMain() {
    const { auth } = this.props;
    const copyright = (
      <div>
        Copyright <Icon type="copyright" /> 2019 CoderTest
      </div>
    );
    if (!isEmpty(auth.data)) {
      return (
        <Layout>
          <Header
            style={{
              background: "#fff",
              padding: 0,
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <GlobalHeader
              currentUser={{
                avatar:
                  "https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png",
                name: "Admin"
              }}
              collapsed={this.state.collapsed}
              onCollapse={this.toggle}
              onMenuClick={() => {}}
            />
          </Header>
          <Content style={{ margin: "24px 24px 0", height: "100%" }}>
            <Switch>
              <Route exact path="/" component={Accounts} />
            </Switch>
          </Content>
          <Footer style={{ padding: 0 }}>
            <div style={{ background: "#f5f5f5", overflow: "hidden" }}>
              <GlobalFooter copyright={copyright} />
            </div>
          </Footer>
        </Layout>
      );
    } else return null;
  }

  render() {
    const { auth } = this.props;
    console.log(this.props);
    return (
      <Switch>
        <Route exact path="/login" component={Login} />
        {auth.error && !auth.loading ? (
          <Redirect to="/login" />
        ) : auth.loading ? (
          this.renderLoading()
        ) : (
          <React.Fragment>
            <Layout>
              <SiderMenu
                trigger={null}
                collapsible
                collapsed={this.state.collapsed}
              />

              {this.renderMain()}
            </Layout>
          </React.Fragment>
        )}
      </Switch>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  auth
});
export default compose(
  withRouter,
  connect(
    mapStateToProps,
    { fetchUser }
  )
)(App);
