import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Menu, Icon, Spin, Dropdown } from "antd";

import { logoutUser } from "../../actions";

import { withRouter, Link } from "react-router-dom";

import "./styles.css";

class GlobalHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
    this.inputRef = React.createRef();
  }

  toggle = () => {
    const { collapsed, onCollapse } = this.props;
    onCollapse(!collapsed);
  };

  render() {
    const { currentUser, collapsed, onMenuClick } = this.props;

    return (
      <React.Fragment>
        <Icon
          type={collapsed ? "menu-unfold" : "menu-fold"}
          onClick={this.toggle}
          className="sider-menu-icon"
        />
        <div className="right-end">
          {currentUser.name ? (
            <span>{currentUser.name}</span>
          ) : (
            <Spin size="small" style={{ marginLeft: 8 }} />
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  auth
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    { logoutUser }
  )
)(GlobalHeader);
