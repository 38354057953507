import {
  FETCH_INTERVIEWS_STARTED,
  FETCH_INTERVIEWS_SUCCESS,
  FETCH_INTERVIEWS_FAILURE,
  DELETE_INTERVIEW_STARTED,
  DELETE_INTERVIEW_SUCCESS,
  DELETE_INTERVIEW_FAILURE
} from "../actions/types";

const initialInterviewsState = {
  loading: false,
  data: [],
  error: null
};

export const interviews = (state = initialInterviewsState, action) => {
  switch (action.type) {
    case FETCH_INTERVIEWS_STARTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_INTERVIEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case FETCH_INTERVIEWS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case DELETE_INTERVIEW_STARTED:
      return {
        ...state,
        loading: true
      };
    case DELETE_INTERVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case DELETE_INTERVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
