import React, { Component } from "react";
import PageHeader from "ant-design-pro/lib/PageHeader";
import { connect } from "react-redux";
import { Table, Row, Card, Col, Drawer, List, Divider } from "antd";
import isEmpty from "lodash.isempty";
import { fetchAccounts } from "../../actions";
import DetailedView from "./DetailedView";
// import AddDriver from "./AddDriver";

class Accounts extends Component {
  state = { visible: false, record: null };

  componentDidMount() {
    this.props.fetchAccounts();
  }

  showDrawer = record => {
    this.setState({
      visible: true,
      record
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
      record: null
    });
  };

  renderDrawer() {
    const { visible, record } = this.state;
    if (record) {
      return (
        <DetailedView
          onClose={this.onClose}
          visible={visible}
          record={record}
        />
      );
    } else return null;
  }
  render() {
    const { accounts } = this.props;

    if (accounts.loading) {
      return null;
    } else
      return (
        <React.Fragment>
          <div style={{ margin: "-24px -24px 0" }}>
            <PageHeader key="pageheader" title="Accounts" action={[]} />
          </div>
          <Row
            gutter={24}
            style={{ margin: "24px auto 0px auto", maxWidth: "1000px" }}
          >
            <Col span={24}>
              <Card>
                <List
                  dataSource={accounts.data}
                  bordered
                  renderItem={item => (
                    <React.Fragment>
                      <List.Item
                        key={item._id}
                        actions={[
                          <a
                            onClick={() => this.showDrawer(item)}
                            key={`a-${item._id}`}
                          >
                            Details
                          </a>
                        ]}
                      >
                        <List.Item.Meta title={item.name} />
                      </List.Item>
                    </React.Fragment>
                  )}
                />
              </Card>
            </Col>
          </Row>
          {this.renderDrawer()}
        </React.Fragment>
      );
  }
}
const mapStateToProps = ({ accounts }) => ({
  accounts
});

export default connect(
  mapStateToProps,
  { fetchAccounts }
)(Accounts);
