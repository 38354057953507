import React from "react";
import { connect } from "react-redux";
import { Table, Row, Card, Col, Drawer, List, Divider } from "antd";
import isEmpty from "lodash.isempty";
import moment from "moment";

import { fetchUsers, fetchInterviews } from "../../actions";

class DetailedView extends React.Component {
  componentDidMount() {
    const { record } = this.props;
    if (record && record._id) {
      this.props.fetchUsers(record._id);
      this.props.fetchInterviews(record._id);
    }
  }

  render() {
    const { onClose, visible, users, interviews } = this.props;
    console.log(this.props);

    const userColumns = [
      {
        title: "Name",
        dataIndex: "_user.local.name",
        key: "_user.local.name"
      },
      {
        title: "Email",
        dataIndex: "_user.local.email",
        key: "_user.local.email"
      },
      {
        title: "Access",
        dataIndex: "access",
        key: "access"
      }
    ];

    const interviewColumns = [
      {
        title: "Title",
        dataIndex: "title",
        key: "title"
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status"
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type"
      },
      {
        title: "Created",
        key: "createdAt",
        render: (text, record) => (
          <div>{moment(record.createdAt).fromNow()}</div>
        )
      }
    ];

    if (users.loading || interviews.loading) {
      return null;
    } else
      return (
        <Drawer
          width={640}
          placement="right"
          closable={false}
          onClose={onClose}
          visible={visible}
        >
          <h3 style={{ marginTop: "20px" }}>Users</h3>
          <Table
            dataSource={users.data}
            columns={userColumns}
            showHeader={true}
            rowKey={record => record._id}
          />
          <h3 style={{ marginTop: "20px" }}>Interviews</h3>
          <Table
            dataSource={interviews.data}
            columns={interviewColumns}
            showHeader={true}
            rowKey={record => record._id}
          />
        </Drawer>
      );
  }
}

const mapStateToProps = ({ users, interviews }) => ({
  users,
  interviews
});

export default connect(
  mapStateToProps,
  { fetchUsers, fetchInterviews }
)(DetailedView);
