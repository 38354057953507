import {
  FETCH_ACCOUNTS_STARTED,
  FETCH_ACCOUNTS_SUCCESS,
  FETCH_ACCOUNTS_FAILURE,
  FETCH_USERS_STARTED,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE
} from "../actions/types";

const initialAccountsState = {
  loading: false,
  data: [],
  error: null
};

export const accounts = (state = initialAccountsState, action) => {
  switch (action.type) {
    case FETCH_ACCOUNTS_STARTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_ACCOUNTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case FETCH_ACCOUNTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};


const initialUsersState = {
  loading: false,
  data: [],
  error: null
};

export const users = (state = initialUsersState, action) => {
  switch (action.type) {
    case FETCH_USERS_STARTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case FETCH_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
};
