import React, { Component } from "react";
import { Menu, Icon } from "antd";
import { Link, withRouter } from "react-router-dom";

const keys = {
  "/": "1"
};

class SiderMenu extends Component {
  _isMounted = false;

  state = {
    path: "",
    selectedKeys: []
  };
  componentDidMount() {
    this._isMounted = true;

    this.setState({ selectedKeys: [keys[this.props.location.pathname]] });
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ selectedKeys: [keys[this.props.location.pathname]] });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { collapsed } = this.props;

    if (!this._isMounted) {
      return null;
    } else
      return (
        <Menu
          mode="inline"
          theme="dark"
          inlineCollapsed={collapsed}
          selectedKeys={this.state.selectedKeys}
        >
          <Menu.Item
            title={"CoderTest-Admin"}
            key="index"
            style={{
              marginBottom: "24px",
              marginTop: "24px",
              height: "100%",
              backgroundColor: "#001529"
            }}
          >
            <Link to="/">
              <Icon type="rocket" />
              <span>CoderTest-Admin</span>
            </Link>
          </Menu.Item>

          <Menu.Item key="1">
            <Link to="/">
              <Icon type="team" /> 
              <span>Accounts</span>
            </Link>
          </Menu.Item>
        </Menu>
      );
  }
}

export default withRouter(SiderMenu);
