import axios from "axios";
import {
  FETCH_ACCOUNTS_STARTED,
  FETCH_ACCOUNTS_SUCCESS,
  FETCH_ACCOUNTS_FAILURE,
  FETCH_USERS_STARTED,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  FETCH_INTERVIEWS_STARTED,
  FETCH_INTERVIEWS_SUCCESS,
  FETCH_INTERVIEWS_FAILURE,
  DELETE_INTERVIEW_STARTED,
  DELETE_INTERVIEW_SUCCESS,
  DELETE_INTERVIEW_FAILURE
} from "./types";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchAccounts = () => async dispatch => {
  try {
    dispatch({ type: FETCH_ACCOUNTS_STARTED });
    const res = await axios.get(`${API_BASE_URL}/api/v1/admin/account`, {
      withCredentials: true
    });
    let { accounts } = res.data;
    dispatch({ type: FETCH_ACCOUNTS_SUCCESS, payload: accounts });
  } catch (error) {
    dispatch({
      type: FETCH_ACCOUNTS_FAILURE,
      payload: error.response.data
    });
  }
};

export const fetchUsers = id => async dispatch => {
  try {
    dispatch({ type: FETCH_USERS_STARTED });
    const res = await axios.get(
      `${API_BASE_URL}/api/v1/admin/account/${id}/users`,
      {
        withCredentials: true
      }
    );
    let { users } = res.data;
    dispatch({ type: FETCH_USERS_SUCCESS, payload: users });
  } catch (error) {
    dispatch({
      type: FETCH_USERS_FAILURE,
      payload: error.response.data
    });
  }
};

export const fetchInterviews = id => async dispatch => {
  try {
    dispatch({ type: FETCH_INTERVIEWS_STARTED });
    const res = await axios.get(
      `${API_BASE_URL}/api/v1/admin/account/${id}/interviews`,
      {
        withCredentials: true
      }
    );
    let { interviews } = res.data;
    dispatch({ type: FETCH_INTERVIEWS_SUCCESS, payload: interviews });
  } catch (error) {
    dispatch({
      type: FETCH_INTERVIEWS_FAILURE,
      payload: error.response.data
    });
  }
};

export const deleteInterview = (accountId, interviewId) => async dispatch => {
  try {
    dispatch({ type: DELETE_INTERVIEW_STARTED });
    await axios.get(`${API_BASE_URL}/api/v1/admin/interviews/${interviewId}`, {
      withCredentials: true
    });

    dispatch(fetchInterviews(accountId));
  } catch (error) {
    dispatch({
      type: DELETE_INTERVIEW_FAILURE,
      payload: error.response.data
    });
  }
};
