import React, { Component } from "react";
import Login from "ant-design-pro/lib/Login";
import { Alert } from "antd";
import { Link } from "react-router-dom";
import { loginUser, clearAuthState } from "../../actions";
import { connect } from "react-redux";
import { compose } from "redux";
import { Redirect, withRouter } from "react-router";

import "./styles.css";
const { Tab, UserName, Password, Submit } = Login;

class AuthForm extends Component {
  state = {
    notice: "",
    type: "tab1",
    error: null
  };

  componentDidMount() {
    this.props.clearAuthState();
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.auth !== prevProps.auth && this.props.auth.error) {
      this.setState({ error: this.props.auth.error });
    }
  }
  onSubmit = (err, values) => {
    const { type, invite } = this.props;

    if (!err) {
      this.setState({ notice: "" }, () => {
        let { email, password, name } = values;
        if (type === "login") {
          return this.props.loginUser(email, password, () =>
            this.props.history.push("/")
          );
        }
      });
    }
  };

  renderMessage = content => {
    return (
      <Alert
        style={{ marginBottom: 24 }}
        message={content}
        type="error"
        showIcon
      />
    );
  };

  render() {
    const { type, auth, invite } = this.props;
    const { error } = this.state;
    console.log({ auth });
    if (
      auth &&
      auth.data &&
      auth.data._user &&
      auth.data._user.local &&
      type !== "invitation"
    ) {
      return <Redirect to="/" />;
    }
    return (
      <div className="auth-container">
        <div className="auth-content">
          <div className="auth-main">
            <Login defaultActiveKey={this.state.type} onSubmit={this.onSubmit}>
              <Tab key="tab1" tab="Account">
                {error ? this.renderMessage(error.message) : null}
                {type === "login" ? null : (
                  <UserName
                    name="name"
                    placeholder="Full Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your full name!"
                      }
                    ]}
                  />
                )}

                <UserName
                  name="email"
                  placeholder="Email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your email address!"
                    }
                  ]}
                />

                <Password
                  name="password"
                  placeholder="Password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a password!"
                    }
                  ]}
                />
              </Tab>
              <Submit>{type === "login" ? "Login" : "Register"}</Submit>

              <div>
                <Link
                  to={type === "login" ? "/register" : "/login"}
                  style={{ float: "right" }}
                >
                  {type === "login" ? "Register" : "Login"}
                </Link>
              </div>
            </Login>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({ auth });

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    { loginUser, clearAuthState }
  )
)(AuthForm);
