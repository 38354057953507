import axios from "axios";
import {
  FETCH_CURRENT_USER_STARTED,
  FETCH_CURRENT_USER_SUCCESS,
  FETCH_CURRENT_USER_FAILURE,
  LOGIN_USER_STARTED,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  SIGNUP_USER_STARTED,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAILURE,
  LOGOUT_USER,
  CLEAR_AUTH_STATE
} from "./types";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchUser = () => async dispatch => {
  try {
    dispatch({ type: FETCH_CURRENT_USER_STARTED });
    const res = await axios.get(`${API_BASE_URL}/api/v1/admin/current_user`, {
      withCredentials: true
    });
    let user = res.data;
    if (user.admin) {
      dispatch({ type: FETCH_CURRENT_USER_SUCCESS, payload: res.data });
    } else {
        console.log("User is not Admin!")
      dispatch({
        type: FETCH_CURRENT_USER_FAILURE,
        payload: "User is not Admin!"
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: FETCH_CURRENT_USER_FAILURE,
      payload: error.response.data
    });
  }
};

export const loginUser = (email, password, cb) => async dispatch => {
  try {
    dispatch({ type: LOGIN_USER_STARTED });
    const res = await axios.post(
      `${API_BASE_URL}/api/v1/admin/login`,
      {
        email,
        password
      },
      { withCredentials: true }
    );
    dispatch({ type: LOGIN_USER_SUCCESS, payload: res.data });
    cb();
    if (!res.data.message) {
      dispatch(fetchUser());
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: LOGIN_USER_FAILURE, payload: error.response.data });
  }
};

export const logoutUser = callback => async dispatch => {
  const res = await axios.get(`${API_BASE_URL}/api/v1/admin/logout`, {
    withCredentials: true
  });
  dispatch({ type: LOGOUT_USER, payload: res.data });
  callback();
};

export const clearAuthState = () => dispatch => {
  dispatch({ type: CLEAR_AUTH_STATE, payload: {} });
};
